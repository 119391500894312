/* global Component */
class itemSliderComponent extends Component {

    static name() {
        return "itemSliderComponent";
    }

    static componentName() {
        return "itemSliderComponent";
    }

    getProps() {
        /*
            showCarusel:        muestra las miniaturas abajo de la imagen principal si hay mas de una
            caruselOnLeftSide:  muestra las miniaturas a la izquierda de la imagen principal si hay mas de una
            slideBigImage:      muestra las flechas a los costados de la imagen principal si hay mas de una
        */
        return ['item','useZoom','container',"stockFlag","showCarusel", "slideBigImage", "itemInSlider", "imgNumber", "carouselOnLeftSide"];
    }

    getWatch() {
        return  {
            "item.Code": function () {
                this.slidesItem = [];
                this.selected = 0;
                this.minIndex = 0;
                this.getItemImage();
            }
        };
    }


    data() {
        return {
            slidesItem:[],
            selected: 0,
            minIndex:0,
            comment: "",
            itemLegendTextColor: "",
            itemLegendColor: "",
        };
    }

    mounted() {
        return function () {
            this.getItemImage();
            this.selected = this.imgNumber || 0;
            if(this.selected > this.slidesItem.length - 1)
                this.selected = 0;
            if(!this.container) {
                let index = 0;
                for(let item of this.slidesItem) {
                    if(item && !item.is360) {
                        this.selected = index;
                        break;
                    }
                    index++;
                }
            }
        };
    }

    getComputed() {
        return {
            itemCode:function  (){
                return this.item.Code;
            },
            noStock:function () {
                if(this.item.Qty <= 0 && this.stockFlag)
                    return true;
                return false;
            },
            showItemComment: function () {
                if(this.item.ItemLegend) {
                    this.comment = this.item.ItemLegend;
                    this.itemLegendTextColor = this.item.ItemLegendTextColor ? this.item.ItemLegendTextColor : '';
                    this.itemLegendColor = this.item.ItemLegendColor ? this.item.ItemLegendColor : '';
                    return true;
                } else {
                    if(this.noStock) {
                        this.comment = this.tr("Sold out");
                        return true;
                    }
                }
                return false;
            },
            maxIndex:function (){
                if(this.itemInSlider)
                    this.itemInSlider;
                return this.slidesItem.length;
            }
        };
    }

    getMethods() {
        return {
            clickEvent:this.clickEvent,
            selectImage:this.selectImage,
            getItemImage:this.getItemImage,
            shouldRenderImg:this.shouldRenderImg,
            moveLeft:this.moveLeft,
            moveRight:this.moveRight
        };
    }

    selectImage(index){
        this.selected = index;
    }

    getItemImage(){
        if(this.item && this.item.slideIds) {
            let imagesList = [];
            let slideValue = this.item.slideIds;
            if(!Array.isArray(slideValue) && typeof slideValue === 'string'){
                imagesList = this.item.slideIds.split(",");
            }
            else{
                imagesList = this.item.slideIds;
            }
            let slidesItem = [];
            let iter = 0;
            for (let imgId of imagesList) {
                let dataDesTruct = imgId.split("--");
                let is360  = false;
                if(dataDesTruct.length > 1)
                    is360 = dataDesTruct[1] === "360";
                slidesItem.push({src:this.item.images[iter],is360:is360});
                iter += 1;
            }
            this.slidesItem = slidesItem;
            return slidesItem[0];
        }
        return [];
    }

    shouldRenderImg(index) {
        return (index >= this.minIndex && index < this.minIndex + this.maxIndex);
    }

    moveLeft(){
        if(this.minIndex > 0) {
            this.minIndex = this.minIndex - 1;
            this.selected = this.minIndex;
        }
    }

    moveRight(){
        if(this.minIndex + 1 < this.slidesItem.length) {
            this.minIndex = this.minIndex + 1;
        }
        else {
            this.minIndex =  0;
        }
        this.selected = this.minIndex;
    }

    clickEvent(){
        return this.emitEvent('modalItemCick');
    }

    getTemplate() {
        return `<div class="row m-0 item-slider-component" :key="itemCode">
                   <div @click="clickEvent" class="p-0 item-slider-component-image" :class="{'order-2 col-9 col-xl-10': carouselOnLeftSide, 'col-12': !carouselOnLeftSide}">
                         <template v-if="showItemComment">
                            <div class="no-stock-image"><span :style="{ color: itemLegendTextColor, backgroundColor: itemLegendColor }">{{comment}}</span></div>
                         </template>
                         <template v-if="slidesItem.length > 0">
                            <template v-if="container">
                                <span class="slider-item-control-left big-image-control" v-if="slideBigImage && slidesItem.length > 1" @click="moveLeft()"><i class="icon fas fa-chevron-left fa-2x"></i></span>
                                    <imgProxyComponent :useZoom="useZoom" :is360="slidesItem[selected].is360" myclass="item-image img-fluid" :isthumbnails="false" :src="slidesItem[selected].src" mystyle="min-height:190px;"/>
                                <span class="slider-item-control-right big-image-control" v-if="slideBigImage && slidesItem.length > 1" @click="moveRight()"><i class="icon fas fa-chevron-right fa-2x"></i></span>
                            </template>
                            <template v-else>
                                <imgProxyComponent :useZoom="useZoom" :is360="!!slidesItem[selected].is360"  myclass="item-image img-fluid" :isthumbnails="false" :src="slidesItem[selected].src" />
                            </template>
                         </template>
                         <template v-else> 
                            <imgProxyComponent :useZoom="false" :is360="false"  />
                         </template>
                  </div>
                  <template v-if="showCarusel && slidesItem.length > 1">
                        <div class="p-0" :class="{'order-1 mt-0 col-3 col-xl-2': carouselOnLeftSide, 'col-12 mt-2': !carouselOnLeftSide}">
                            <div class="row justify-content-center">
                                <span class="slider-item-control-left" v-if="slidesItem.length>4 && !carouselOnLeftSide" @click="moveMinicarouselLeft()"><i class="fas fa-chevron-left fa-2x"></i></span>
                                <div class="row slider-item-minicarousel">
                                    <template v-for="(image,index) of slidesItem">
                                        <div class="thumbnail" :class="{'selected':index==selected, 'w-100': carouselOnLeftSide}" v-if="shouldRenderImg(index)">
                                            <imgProxyComponent :useZoom="useZoom" :is360="image.is360" :key="index" myclass="item-image img-fluid" :isthumbnails="true" :src="image.src" alt="Product Image" @click.native="selectImage(index)" :imgIndex="index" />
                                        </div>
                                    </template>
                                </div>
                                <span class="slider-item-control-right" v-if="slidesItem.length>4 && !carouselOnLeftSide" @click="moveMinicarouselRight()"><i class="fas fa-chevron-right fa-2x"></i></span>
                            </div>
                        </div>
                 </template>
              </div>`;
    }
}

itemSliderComponent.registerComponent();
